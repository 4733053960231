import React, { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import './EpisodeCard.css';
import { setCurrentWatch } from "../appSlice";
import { useHistory } from "react-router-dom";
// Correct way to import SVG as a default export
import imagePlaceholder from '../../assets/imagePlaceholder.png';

const EpisodeCard = React.memo(({ episode, series }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const episodeRef = useRef(episode);
  const cardRef = useRef(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(imagePlaceholder); // Use the imported placeholder

  useEffect(() => {
    // Create a new Image object to check the thumbnail size
    const img = new Image();

    // Check if the dimensions are valid once the image is loaded
    img.onload = function () {
      if (img.width < 1280 || img.height < 155) {
        // If the dimensions are invalid, update the thumbnail to use the 'mqdefault.jpg'
        setThumbnailUrl(`https://i.ytimg.com/vi/${episode.video_id}/mqdefault.jpg`);
      } else {
        // Set the actual thumbnail URL if dimensions are valid
        setThumbnailUrl(episode.thumbnail_url);
      }
    };

    // If there's an error loading the image, also fall back to 'mqdefault.jpg'
    img.onerror = function () {
      setThumbnailUrl(`https://i.ytimg.com/vi/${episode.video_id}/mqdefault.jpg`);
    };

    // Set the image source to trigger the onload event
    img.src = episode.thumbnail_url;
  }, [episode.thumbnail_url, episode.video_id]);

  useEffect(() => {
    episodeRef.current = episode;
  }, [episode]);

  const goToWatch = useCallback((selectedEpisode) => {
    dispatch(setCurrentWatch({ series: series, selectedEpisode: selectedEpisode, data: null, isPlaying: false, isPlayerReady: false }));
    history.push(`/watch/${series.series_id}/${selectedEpisode.episode_id}`);
  }, [dispatch, history, series]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        if (cardRef.current && document.activeElement === cardRef.current) {
          goToWatch(episodeRef.current);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [goToWatch]);

  return (
    <div 
      ref={cardRef}
      className={`episodeCard focusable mb-4`} 
      data-title={episode.episode_title} 
      data-videoid={episode.video_id}
      onClick={() => goToWatch(episode)}
      tabIndex={0}
    >
      {series.is_series && (
        <div className="episodeTag">
          {series.title}
        </div>
      )}
      <img 
        src={thumbnailUrl} 
        alt={episode.episode_title} 
        loading="lazy" // Lazy load image
      />
    </div>
  );
});

export default EpisodeCard;
