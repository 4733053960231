import { useEffect, useRef } from "react";
import SpatialNavigation from "spatial-navigation-js";

const useSpatialNavigation = () => {
  const isSNInitialized = useRef(false);

  useEffect(() => {
    let cleanupFunction = () => {}; // Placeholder for cleanup function

    if (!isSNInitialized.current) {
      SpatialNavigation.init();

      SpatialNavigation.add({
        id: "upperbox",
        selector: "#upperbox .focusable",
        defaultElement: "#upperbox .focusable:first-child",
        enterTo: "last-focused",
      });

      SpatialNavigation.makeFocusable();
      SpatialNavigation.focus("upperbox");

      isSNInitialized.current = true;

      // Define cleanup function to remove event listener and SpatialNavigation entries
      cleanupFunction = () => {
        SpatialNavigation.remove("upperbox");
        isSNInitialized.current = false;
      };
    }

    // Cleanup on component unmount
    return () => {
      cleanupFunction();
    };
  }, []);
};

export default useSpatialNavigation;
