import React, { useState, useEffect, useRef, useCallback } from "react";
import background from "../../assets/Black-50-transparent.png";
import {
  PlayFill, PauseFill,
  ArrowClockwise, ArrowCounterclockwise, VolumeMute, VolumeUp
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { selectApp, setCurrentWatch } from "../appSlice";
import throttle from "lodash/throttle";
import SpatialNavigation from "spatial-navigation-js";

const PlaybackControls = ({ onPlayPause, playerRef }) => {
  const { isPlaying, isMuted, selectedEpisode, data } = useSelector(selectApp).currentWatch;
  const [showControls, setShowControls] = useState(true);
  const [progress, setProgress] = useState(0);
  const [watchedTime, setWatchedTime] = useState("0:00");
  const [durationTime, setDurationTime] = useState("0:00");
  const [isProgressBarFocused, setIsProgressBarFocused] = useState(false);
  const animationFrameRef = useRef(null);
  const seekTimeoutRef = useRef(null); // Ref for the timeout
  const isSeekingRef = useRef(false); // Ref to track if we're seeking
  const pausedManuallyRef = useRef(false); // Track if the video was paused manually
  const hideControlsTimeoutRef = useRef(null);

  // Refs for buttons
  const rewindButtonRef = useRef(null);
  const playPauseButtonRef = useRef(null);
  const forwardButtonRef = useRef(null);
  const muteButtonRef = useRef(null);
  const lastFocusedElement = useRef(null); // Ref to store last focused element


  const dispatch = useDispatch();
  
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
  
    // Format to ensure two digits for minutes and seconds
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  
    // If duration has hours, display it; otherwise, only show minutes and seconds
    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  };

  const throttledSeekPlayerRef = useRef(
    throttle(async (seekTime) => {
      const player = playerRef.current?.internalPlayer;
      if (player) {
        await player.seekTo(seekTime, true);
        setTimeout(() => {
          isSeekingRef.current = false;  // Mark seeking as done
        }, 300);
      }
    }, 200)
  );
  
  const handleSeek = useCallback((newSeekTime) => {
    isSeekingRef.current = true;
    throttledSeekPlayerRef.current(newSeekTime);
  }, []);
  
  const updateProgress = useCallback(async () => {
    const player = playerRef.current?.internalPlayer;
    if (player && !isSeekingRef.current) {
      const currentTime = await player.getCurrentTime();
      const duration = await player.getDuration();
      setProgress((currentTime / duration) * 100);
      setWatchedTime(formatTime(currentTime));
      setDurationTime((duration));
      // dispatch(setCurrentWatch({ currentTime }));
      if(data.category_id !== "1") {
        localStorage.setItem(selectedEpisode.video_id.toString(), currentTime);
      }
    }
  }, [selectedEpisode.video_id, data.category_id, playerRef]);
  
  const animateProgress = useCallback(async () => {
    const player = playerRef.current?.internalPlayer;
    if (player && isPlaying && !isSeekingRef.current) {
      await updateProgress();
      animationFrameRef.current = requestAnimationFrame(animateProgress);
    }
  }, [isPlaying, updateProgress, playerRef]);  
  
  useEffect(() => {
    if (isPlaying && !isSeekingRef.current) {
      animationFrameRef.current = requestAnimationFrame(animateProgress);
    } else {
      cancelAnimationFrame(animationFrameRef.current);
    }
    return () => cancelAnimationFrame(animationFrameRef.current); // Cleanup on unmount or when paused
  }, [isPlaying, animateProgress]);


  useEffect(() => {
    if (isProgressBarFocused) {
      SpatialNavigation.disable("watch-sidebar");
      dispatch(setCurrentWatch({ showSidebar: false }));
    } else {
      SpatialNavigation.enable("watch-sidebar");
    }
  }, [dispatch, isProgressBarFocused]);

  const onSeekStart = useCallback(() => {
    const player = playerRef.current?.internalPlayer;
    if (player && !isSeekingRef.current) {
      player.pauseVideo();
      pausedManuallyRef.current = true;
      isSeekingRef.current = true;
    }
    clearTimeout(seekTimeoutRef.current);
  }, [playerRef]);

  const onSeekEnd = useCallback(() => {
    clearTimeout(seekTimeoutRef.current);

    seekTimeoutRef.current = setTimeout(() => {
      const player = playerRef.current?.internalPlayer;
      if (player && pausedManuallyRef.current) {
        player.playVideo();
        pausedManuallyRef.current = false;
        isSeekingRef.current = false;       
      }
    }, 500);
  }, [playerRef]);

  const resetHideControlsTimeout = useCallback(() => {
    const timeout = isProgressBarFocused ? 5000 : 3000;
    setShowControls(true);
    clearTimeout(hideControlsTimeoutRef.current);

    hideControlsTimeoutRef.current = setTimeout(() => {
      lastFocusedElement.current = document.activeElement;

      if (isProgressBarFocused) {
        playPauseButtonRef.current.focus();
      }
      setShowControls(false);
      dispatch(setCurrentWatch({ showSidebar: false }));
    }, timeout);
  }, [dispatch, isProgressBarFocused]);

  // Show/Hide Controls logic
  useEffect(() => {
    resetHideControlsTimeout();

    const handleMouseMove = () => resetHideControlsTimeout();

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      clearTimeout(hideControlsTimeoutRef.current);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [resetHideControlsTimeout]);


  useEffect(() => {
    const handleKeyDown = (event) => {
      resetHideControlsTimeout();
      if (isProgressBarFocused) {
        const player = playerRef.current?.internalPlayer;
        if (player) {
          player.getDuration().then((duration) => {
            if (!duration || isNaN(duration)) {
              console.error("Duration is not available or invalid.");
              return;
            }
  
            setDurationTime(duration);
  
            if (event.keyCode === 37) { // Left arrow
              const percentageChange = -1;
              const newProgress = Math.max(0, progress + percentageChange);
              setProgress(newProgress);
  
              const newTime = (newProgress / 100) * duration;
              setWatchedTime(formatTime(newTime));
            } else if (event.keyCode === 39) { // Right arrow
              const percentageChange = 2;
              const newProgress = Math.min(100, progress + percentageChange);
              setProgress(newProgress);
  
              const newTime = (newProgress / 100) * duration;
              setWatchedTime(formatTime(newTime));
            }
            onSeekStart(); // Start seeking
          });
        }
      }
    };
  
    const handleKeyUp = async () => {
      if (isProgressBarFocused && isSeekingRef.current) {
        const player = playerRef.current?.internalPlayer;
        if (player) {
          const newSeekTime = (progress / 100) * durationTime;
          handleSeek(Math.floor(newSeekTime));
        }
        onSeekEnd(); // End seeking
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp); // Detect key release
  
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [playerRef, resetHideControlsTimeout, durationTime, isProgressBarFocused, progress, handleSeek, onSeekEnd, onSeekStart]); // Dependencies specific to progress
  
  
  

  useEffect(() => {
    if (showControls && lastFocusedElement.current) {
      lastFocusedElement.current.focus();
    }
  }, [showControls]);

  const rewind = useCallback(() => {    
    playerRef.current?.internalPlayer.getCurrentTime().then(currentTime => {
      const newTime = currentTime - 10;
      onSeekStart();
      handleSeek(newTime);
      setTimeout(() => {
        onSeekEnd(); 
      }, 700); 
    });
  }, [playerRef, handleSeek, onSeekEnd, onSeekStart]);

  const forward = useCallback(() => {    
    playerRef.current?.internalPlayer.getCurrentTime().then(currentTime => {
      const newTime = currentTime + 10;
      onSeekStart();
      handleSeek(newTime);
      setTimeout(() => {
        onSeekEnd(); 
      }, 700); 
    });
  }, [playerRef, handleSeek, onSeekEnd, onSeekStart]);

  const toggleMute = useCallback(async () => {
    const player = playerRef.current?.internalPlayer;
    if (player) {
      if (isMuted) {
        await player.setVolume(100);
        await player.unMute();
        dispatch(setCurrentWatch({ isMuted: false, volume: 100 }));
      } else {
        await player.setVolume(0);
        await player.mute();
        dispatch(setCurrentWatch({ isMuted: true, volume: 0 }));
      }
    }
  }, [dispatch, isMuted, playerRef]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        const focusedElement = document.activeElement;
        switch (focusedElement) {
          case rewindButtonRef.current:
            rewind();
            break;
          case playPauseButtonRef.current:
            onPlayPause();
            break;
          case forwardButtonRef.current:
            forward();
            break;
          case muteButtonRef.current:
            toggleMute();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [rewind, forward, onPlayPause, toggleMute]);

  return (
    <div
      className={`d-flex flex-column justify-content-center overflow-hidden ${showControls ? '' : 'd-none'} control`}
      onMouseMove={() => setShowControls(true)}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="d-flex justify-content-center">
        <button 
          ref={rewindButtonRef}
          className="focusable btn btn-dark p-2 m-2 d-flex justify-content-center" 
          // onClick={rewind}
        >
          <ArrowCounterclockwise color="royalblue" size={25} />
        </button>
        
        <button
          ref={playPauseButtonRef}
          className="focusable btn btn-dark p-2 m-2 d-flex justify-content-center" 
          // onClick={onPlayPause}
        >
          {isPlaying ? <PauseFill color="royalblue" size={25} /> : <PlayFill color="orange" size={25} />}
        </button>

        <button
          ref={forwardButtonRef}
          className="focusable btn btn-dark p-2 m-2 d-flex justify-content-center"
          // onClick={forward}
        >
          <ArrowClockwise color="royalblue" size={25} />
        </button>
        
        <button 
          ref={muteButtonRef}
          className="focusable btn btn-dark p-2 m-2 d-flex justify-content-center" 
          // onClick={toggleMute}
        >
          {isMuted ? <VolumeMute color="royalblue" size={25} /> : <VolumeUp color="royalblue" size={20} />}
        </button>
      </div>

      {/* Progress bar */}
      <div
        className="progress focusable"
        style={{ height: '16px' }}
        tabIndex="0"
        onFocus={() => setIsProgressBarFocused(true)}
        onBlur={() => setIsProgressBarFocused(false)}
        onKeyDown={onSeekEnd} // Resume video after releasing key
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%`, backgroundColor: isProgressBarFocused ? 'red' : 'royalblue'  }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>

      {/* Watched Time and Duration */}
      <div className="d-flex justify-content-between text-white m-2 fw-bold">
        <span>{watchedTime}</span>
        <span>{formatTime(durationTime)}</span>
      </div>
    </div>
  );
};

export default PlaybackControls;