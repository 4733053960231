import React, { useRef } from "react";
import YouTube from "react-youtube";
import PlaybackControls from "./PlaybackControls";
import { selectApp, setCurrentWatch } from "../appSlice";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";

const VideoPlayer = () => {
  const dispatch = useDispatch();
  const { isPlaying, isMuted, selectedEpisode, data, isPlayerReady } = useSelector(selectApp).currentWatch;
  const playerRef = useRef(null);
  const videoContainerRef = useRef(null);

  const opts = {
    playerVars: {
      autoplay: 1,
      rel: 0,
    },
  };

  // const currentTimeRef = useRef(currentTime);
  // const isUnmountingRef = useRef(false);

  // useEffect(() => {
  //   currentTimeRef.current = currentTime;
  // }, [currentTime]);
  
  // useEffect(() => {
  //   isUnmountingRef.current = false;
  //   return () => {
  //     isUnmountingRef.current = true;
  //     if(data.category_id !== "1") { //DO NOT STORE RESUME DATA FOR SONGS
  //       setTimeout(() => {
  //         if (isUnmountingRef.current) {
  //           localStorage.setItem(
  //             selectedEpisode.video_id.toString(),
  //             currentTimeRef.current
  //           );
  //         }
  //       }, 0);
  //     }      
      
  //   };
  // }, [selectedEpisode, data.category_id, dispatch]);

  const handlePlayPause = () => {
    const player = playerRef.current.internalPlayer;
    if (isPlaying) {
      player.pauseVideo();
    } else {
      player.playVideo();
    }
    dispatch(setCurrentWatch({ isPlaying: !isPlaying }));
  };

  const onStateChange = (event) => {
    const PlayerState = YouTube.PlayerState;
    if (event.data === PlayerState.PLAYING) {
      dispatch(setCurrentWatch({ isPlaying: true }));
    } else if (
      event.data === PlayerState.PAUSED ||
      event.data === PlayerState.ENDED
    ) {
      dispatch(setCurrentWatch({ isPlaying: false }));
    }
  };

  const onReady = (event) => {
    const player = event.target;
    if (!isMuted) {
      player.setVolume(100);
      player.unMute();
    } else {
      player.setVolume(0);
      player.mute();
    }

    const resume = localStorage.getItem(selectedEpisode.video_id.toString());
    player.seekTo(resume, true);

    setTimeout(() => {
      dispatch(setCurrentWatch({ isPlayerReady: true }));
    }, 1400);
  };

  const onEnd = (e) => {
    const currentWatchId = selectedEpisode.episode_id;
    const nextWatchIndex = data.episodes?.findIndex(
      (episode) => parseInt(episode.episode_id) === parseInt(currentWatchId)
    ) + 1;

    localStorage.removeItem(selectedEpisode.video_id);

    if (nextWatchIndex !== -1 && data.episodes.length > nextWatchIndex) {
      const nextEpisode = data.episodes[nextWatchIndex];
      dispatch(setCurrentWatch({ selectedEpisode: nextEpisode, isPlayerReady: false }));
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div ref={videoContainerRef} className="videoWrapper flex-grow-1">
        {isPlayerReady && <Sidebar episodes={data.episodes} selectedEpisode={selectedEpisode} /> }
        <div hidden={!isPlayerReady}>
          <YouTube
            id="player"
            ref={playerRef}
            videoId={selectedEpisode.video_id}
            opts={opts}
            onStateChange={onStateChange}
            className="no-pointer-events"
            onReady={onReady}
            onEnd={onEnd}
            iframeClassName={'fullscreen'}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
          <div id="playbackControls">
            <PlaybackControls
              onPlayPause={handlePlayPause}
              playerRef={playerRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
