import React from "react";

const Loading = () => {
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    zIndex: 9999, // Ensure it's on top of other elements
    display: "flex",
    justifyContent: "center",
    alignItems: "center", // Center the spinner vertically and horizontally
  };

  return (
    <div style={overlayStyle}>
      <div className="spinner-border text-warning" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  );
};

export default Loading;

