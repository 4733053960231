import React, { useState } from 'react';
import { HashRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import './App.css';
import NavBar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Watch from './components/Watch/Watch';
import GridPage from './components/GridPage/GridPage';
import SplashScreen from './components/SplashScreen/SplashScreen';

function App() {
  const [isSplashVisible, setIsSplashVisible] = useState(true);

  return (
    <div className="App">
      {isSplashVisible && <SplashScreen />}
      <Router>
        <RouteContainer setIsSplashVisible={setIsSplashVisible} />
      </Router>
    </div>
  );
}

function RouteContainer({ setIsSplashVisible }) {
  const location = useLocation();

  React.useEffect(() => {
    // Hide splash screen after initial load
    setTimeout(() => {
      setIsSplashVisible(false);
    }, 5000);
    
  }, [location, setIsSplashVisible]);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return (
            <>
              <NavBar />
              <Home />
            </>
          );
        }}
      />
      <Route
        path="/shows"
        render={() => {
          return (
            <>
              <NavBar />
              <GridPage category="shows" />
            </>
          );
        }}
      />
      <Route
        path="/kids"
        render={() => {
          return (
            <>
              <NavBar />
              <GridPage category="kids"/>
            </>
          );
        }}
      />
      <Route
        path="/movies"
        render={() => {
          return (
            <>
              <NavBar />
              <GridPage category="movies"/>
            </>
          );
        }}
      />
      <Route
        path="/watch"
        render={() => {
          return <Watch />;
        }}
      />
    </Switch>
  );
}

export default App;