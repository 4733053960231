import { useEffect, useRef } from "react";
import SpatialNavigation from "spatial-navigation-js";

const useSpatialNavigation = (animateScrollY, spatialId) => { // Add spatialId as a parameter
  const isSNInitialized = useRef(false);

  useEffect(() => {
    let cleanupFunction = () => {}; // Placeholder for cleanup function

    if (!isSNInitialized.current) {
      SpatialNavigation.init();

      SpatialNavigation.add({
        id: spatialId, // Use the unique ID here
        selector: `#${spatialId} .focusable`,
        enterTo: "last-focused",
        straightOnly: true,
        straightOverlapThreshold: 0.4,
        restrict: "self-first",
        leaveFor: {
          up: "#upperbox .focusable:first-child",
        },
      });

      const handleFocusEvent = (evt) => {
        const targetElement = evt.detail.target || document.activeElement;

        if (evt.detail.direction === "up" || evt.detail.direction === "down") {
          if (targetElement && targetElement !== document.body) {
            animateScrollY(targetElement);
          }
        }
      };

      document.addEventListener("sn:focused", handleFocusEvent);

      SpatialNavigation.makeFocusable();
      SpatialNavigation.focus("upperbox");

      isSNInitialized.current = true;

      // Define cleanup function to remove event listener and SpatialNavigation entries
      cleanupFunction = () => {
        document.removeEventListener("sn:focused", handleFocusEvent);
        SpatialNavigation.remove(spatialId); // Use the unique ID for cleanup
        isSNInitialized.current = false;
      };
    }

    return () => {
      cleanupFunction();
    };
  }, [animateScrollY, spatialId]); // Include spatialId as a dependency
};

export default useSpatialNavigation;
