import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectApp, setCurrentWatch } from "../appSlice";

const Sidebar = ({ episodes, selectedEpisode }) => {
  const dispatch = useDispatch();
  const focusableContainerRef = useRef(null);

  const { episode_id } = useSelector(selectApp).currentWatch.selectedEpisode;
  const { isPlayerReady, showSidebar } = useSelector(selectApp).currentWatch;

  useEffect(() => {
    let sidebarTimeout;
    
    const handleInteraction = (event) => {
      // Prevent this handler from affecting playback controls
      if (event.target.closest('#playbackControls')) {
        return;
      }
  
      // Show the sidebar on interaction
      dispatch(setCurrentWatch({ showSidebar: true }));
  
      // Clear any existing timeout to hide the sidebar
      clearTimeout(sidebarTimeout);
  
      // Set a timeout to hide the sidebar after 3 seconds of inactivity
      sidebarTimeout = setTimeout(() => {
        dispatch(setCurrentWatch({ showSidebar: false }));
      }, 3000);
    };
  
    // Add event listeners for keydown and keyup
    window.addEventListener('keydown', handleInteraction);
    window.addEventListener('keyup', handleInteraction);
  
    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleInteraction);
      window.removeEventListener('keyup', handleInteraction);
    };
  }, [dispatch]);
  
  

  const handleEpisodeSelect = useCallback((episode) => {
    if (episode_id !== episode.episode_id) {
      dispatch(setCurrentWatch({ selectedEpisode: episode, isPlayerReady: false, isPlaying: false }));
    }
  },[dispatch, episode_id]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      const focusedElement = document.activeElement;
      if (focusedElement && focusedElement.classList.contains("focusable")) {
        const episode = episodes.find(
          (ep) => ep.episode_id.toString() === focusedElement.dataset.episodeid
        );
        if (episode) {
          handleEpisodeSelect(episode);
        }
      }
    }
  }, [episodes, handleEpisodeSelect]);

  const isElementInCenter = useCallback((element) => {
    const container = focusableContainerRef.current;
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const containerCenter = containerRect.top + containerRect.height / 2;
    const elementCenter = elementRect.top + elementRect.height / 2;

    const centerRegionTop = containerCenter - containerRect.height / 4;
    const centerRegionBottom = containerCenter + containerRect.height / 4;

    return (
      elementCenter >= centerRegionTop && elementCenter <= centerRegionBottom
    );
  }, []);

  const handleScrollY = useCallback(
    (direction) => {
      if (focusableContainerRef.current) {
        const container = focusableContainerRef.current;
        const focusableElements = container.querySelectorAll(".focusable");
        const currentElement = document.activeElement;
        const currentIndex = Array.from(focusableElements).indexOf(currentElement);

        if (currentIndex !== -1) {
          let targetElement;
          if (direction === "down" && currentIndex < focusableElements.length - 1) {
            targetElement = focusableElements[currentIndex + 1];
          } else if (direction === "up" && currentIndex > 0) {
            targetElement = focusableElements[currentIndex - 1];
          }

          if (targetElement && !isElementInCenter(targetElement)) {
            const elementRect = targetElement.getBoundingClientRect();
            const elementHeight = elementRect.height;

            if (direction === "down") {
              container.scrollTop += elementHeight;
            } else if (direction === "up") {
              container.scrollTop -= elementHeight;
            }
          }
        }
      }
    },
    [isElementInCenter]
  );

  useEffect(() => {
    if (focusableContainerRef.current) {
      const activeElement = focusableContainerRef.current.querySelector(".active");
      if (activeElement && !isElementInCenter(activeElement)) {
        const container = focusableContainerRef.current;
        const elementRect = activeElement.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Scroll such that the active element is centered
        container.scrollTop += elementRect.top - (containerRect.top + containerRect.height / 2 - elementRect.height / 2);
      }
    }
  }, [isElementInCenter]);

  useLayoutEffect(() => {
    const handleGlobalKeyDown = (event) => {
      if (focusableContainerRef.current) {
        const focusableElements = focusableContainerRef.current.querySelectorAll(".focusable");
        const currentIndex = Array.from(focusableElements).indexOf(document.activeElement);

        if (currentIndex !== -1) {
          if (event.key === "ArrowDown" && currentIndex <= focusableElements.length - 1) {
            const nextElement = focusableElements[currentIndex];
            if (nextElement) {
              nextElement.focus();
              handleScrollY("down");
            }
          }

          if (event.key === "ArrowUp" && currentIndex >= 0) {
            const prevElement = focusableElements[currentIndex];
            if (prevElement) {
              prevElement.focus();
              handleScrollY("up");
            }
          }
        }
      }
    };

    window.addEventListener("keydown", handleGlobalKeyDown);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleScrollY, handleKeyDown]);

  useEffect(() => {
    if (focusableContainerRef.current) {
      const activeElement = focusableContainerRef.current.querySelector(".active");
      if (activeElement) {
        activeElement.focus(); // Focus the active element
      }
    }
  }, [selectedEpisode]);

  return (
    <div id="sidebar" className={`sidebar-container ${showSidebar && isPlayerReady ? 'visible' : 'hidden'}`}>
      <div
        className="spatial-section focusable-container d-flex flex-column"
        ref={focusableContainerRef}
      >
        {episodes.map((episode, index) => (
          <div
            className={`focusable d-flex align-items-center p-2 row ${
              selectedEpisode &&
              selectedEpisode.episode_id.toString() ===
                episode.episode_id.toString()
                ? "active"
                : ""
            }`}
            key={index}
            data-title={episode.title}
            data-videoid={episode.video_id}
            data-episodeid={episode.episode_id} // Add data attribute for episode ID
            onClick={() => handleEpisodeSelect(episode)}
            tabIndex={0} // Make div focusable
          >
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-5">
              <img
                src={`https://i.ytimg.com/vi/${episode.video_id}/mqdefault.jpg`}
                alt={episode.episode_title}
              />
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-7">
              {episode.episode_title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
