import { useEffect, useRef } from "react";
import SpatialNavigation from "spatial-navigation-js";

const useSpatialNavigation = (animateScrollY) => {
  const isSNInitialized = useRef(false);

  useEffect(() => {
    let cleanupFunction = () => {}; // Placeholder for cleanup function

    if (!isSNInitialized.current) {
      SpatialNavigation.init();

      SpatialNavigation.add({
        id: "middlebox",
        selector: "#middlebox .focusable",
        enterTo: "last-focused",
        straightOnly: true, // Only navigate in a straight line
        restrict: "self-only", // Restrict navigation within the row
        leaveFor: {
          up: "#upperbox .focusable:first-child", // Focus the first element in upperbox when navigating up
        },
      });

      document.addEventListener("sn:focused", handleFocusEvent);

      SpatialNavigation.makeFocusable();
      SpatialNavigation.focus("upperbox");

      isSNInitialized.current = true;

      // Define cleanup function to remove event listener and SpatialNavigation entries
      cleanupFunction = () => {
        document.removeEventListener("sn:focused", handleFocusEvent);
        SpatialNavigation.remove("middlebox");
        isSNInitialized.current = false;
      };
    }

    function handleFocusEvent(evt) {
      const targetElement = evt.detail.target || document.activeElement;
  
      // Check if the event is triggered by arrow key navigation
      if (evt.detail.direction === 'up' || evt.detail.direction === 'down') {
        if (targetElement && targetElement !== document.body) {
          animateScrollY(targetElement);
        }
      }
    }

    // Cleanup on component unmount
    return () => {
      cleanupFunction();
    };
  }, [animateScrollY]);
};

export default useSpatialNavigation;
