// ScrollUtils.js
export const animateScrollY = (targetElement, ListRef) => {
  if (
    !targetElement ||
    !ListRef.current ||
    typeof targetElement.closest !== "function"
  ) {
    return;
  }

  const List = ListRef.current;
  const ListHeight = List.clientHeight;
  const categoryRow = targetElement.closest(".category-row");

  if (!categoryRow) {
    return;
  }

  const categoryRowHeight =
    categoryRow.offsetHeight +
    parseFloat(window.getComputedStyle(categoryRow).marginBottom);

  const targetScrollTop = Math.max(
    0,
    categoryRow.offsetTop - (ListHeight - categoryRowHeight) / 2
  );

  const start = List.scrollTop;
  const change = targetScrollTop - start;
  const duration = 250;
  const increment = 20;

  let currentTime = 0;

  const easeInOutQuad = (t) => {
    t /= duration / 2;
    if (t < 1) return (change / 2) * t * t + start;
    t--;
    return (-change / 2) * (t * (t - 2) - 1) + start;
  };

  const animate = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime);
    List.scrollTop = val;

    if (currentTime < duration) {
      requestAnimationFrame(animate);
    }
  };

  animate();
};

export const animateScrollX = (
  targetPosition,
  scrollPosition,
  seriesListRef,
  setScrollPosition,
  isAnimating
) => {
  if (isAnimating.current) return;

  isAnimating.current = true;

  const seriesList = seriesListRef.current;
  const maxScrollLeft = -(seriesList.scrollWidth - seriesList.clientWidth);
  const clampedPosition = Math.min(Math.max(targetPosition, maxScrollLeft), 0);

  const start = scrollPosition;
  const change = clampedPosition - start;
  const duration = 200;
  const increment = 16;

  let currentTime = 0;

  const easeInOutQuad = (t) => {
    t /= duration / 2;
    if (t < 1) return (change / 2) * t * t + start;
    t--;
    return (-change / 2) * (t * (t - 2) - 1) + start;
  };

  const animate = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime);
    setScrollPosition(val);

    // Avoid unnecessary reflows by only updating transform
    seriesList.style.transform = `translateX(${val}px)`;

    if (currentTime < duration) {
      requestAnimationFrame(animate);
    } else {
      isAnimating.current = false;
    }
  };

  requestAnimationFrame(animate);
};

export const animateScrollVY = (targetElement, ListRef) => {
  if (
    !targetElement ||
    !ListRef.current ||
    typeof targetElement.closest !== "function"
  ) {
    return;
  }

  const List = ListRef.current;
  const ListHeight = List.clientHeight;
  const categoryRow = targetElement.closest(".episodeCard");

  if (!categoryRow) {
    return;
  }

  const categoryRowHeight =
    categoryRow.offsetHeight +
    parseFloat(window.getComputedStyle(categoryRow).marginBottom);

  const targetScrollTop = Math.max(
    0,
    categoryRow.offsetTop - (ListHeight - categoryRowHeight) / 2
  );

  const start = List.scrollTop;
  const change = targetScrollTop - start;
  const duration = 250;
  const increment = 20;

  let currentTime = 0;

  const easeInOutQuad = (t) => {
    t /= duration / 2;
    if (t < 1) return (change / 2) * t * t + start;
    t--;
    return (-change / 2) * (t * (t - 2) - 1) + start;
  };

  const animate = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime);
    List.scrollTop = val;

    if (currentTime < duration) {
      requestAnimationFrame(animate);
    }
  };

  animate();
};

export const animateScrollVideosY = (targetElement, listRef) => {
  if (!targetElement || !listRef.current) {
    return;
  }

  const list = listRef.current;
  const listHeight = list.clientHeight;
  const elementOffsetTop = targetElement.offsetTop;
  const elementHeight = targetElement.offsetHeight;

  const targetScrollTop = Math.max(
    0,
    elementOffsetTop - (listHeight - elementHeight) / 2
  );

  const start = list.scrollTop;
  const change = targetScrollTop - start;
  const duration = 250;
  const increment = 20;

  let currentTime = 0;

  const easeInOutQuad = (t) => {
    t /= duration / 2;
    if (t < 1) return (change / 2) * t * t + start;
    t--;
    return (-change / 2) * (t * (t - 2) - 1) + start;
  };

  const animate = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime);
    list.scrollTop = val;

    if (currentTime < duration) {
      requestAnimationFrame(animate);
    }
  };

  animate();
};

export const scrollToElement = (targetElement, listRef) => {
  if (!targetElement || !listRef.current) {
    return;
  }

  const list = listRef.current;
  const targetTop = targetElement.offsetTop - list.offsetTop;
  list.scrollTop = targetTop;
};