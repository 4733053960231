import React, { useEffect } from "react";
import "./Watch.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataAsync, selectApp, setCurrentWatch } from "../appSlice";
import VideoPlayer from "./VideoPlayer";
import useSpatialNavigationWatch from "./SpatialNavigationWrapperWatch";
import Loading from "../Loading";
import SpatialNavigation from "spatial-navigation-js";
import { useHistory } from "react-router-dom";

const Watch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, status } = useSelector(selectApp);
  const { data, series, isPlayerReady } = useSelector(selectApp).currentWatch;

  const sections = [{ id: "sidebar" }, { id: "playbackControls" }];
  useSpatialNavigationWatch(sections);

  useEffect(() => {
    const fetchDataFromApi = async () => {
      if (series) {
        try {
          const result = await dispatch(
            fetchDataAsync(`watch.php?series_id=${series.series_id}`)
          );
          dispatch(setCurrentWatch({ data: result }));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchDataFromApi();

    SpatialNavigation.disable("middlebox-movies");
    SpatialNavigation.disable("middlebox");

    SpatialNavigation.enable("watch-sidebar");
    SpatialNavigation.enable("playbackControls");

    const handleKeyDown = (event) => {
      if (event.keyCode === 10009) {
        history.goBack();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, series, history]);

  return (
    <div className="main-container">
      {/* Loading screen is displayed when the player isn't ready yet */}
      {(isLoading || !isPlayerReady) && <Loading />}
      {status === "succeeded" && data && (
        <div>
          <div id="center-container" className="d-flex flex-column">
            <VideoPlayer />
          </div>
        </div>
      )}
      {status === "failed" && <p>Error loading data</p>}
    </div>
  );
};

export default Watch;
