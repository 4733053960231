import React, { useRef, useState, useCallback, useEffect } from "react";
import './CategoryRow.css';
import { animateScrollX } from "../ScrollUtils";
import EpisodeCard from "../EpisodeCard/EpisodeCard";

const CategoryRow = ({ data, categoryListRef, cIndex, slideView, hidden }) => {
  const seriesListRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const isAnimating = useRef(false);
  const [currentElement, setCurrentElement] = useState(null);

  const setScrollPositionDebounced = useCallback((newVal) => {
    if (Math.abs(scrollPosition - newVal) > 0.5) {
      setScrollPosition(newVal);
    }
  }, [scrollPosition]);

  const handleScrollX = useCallback(
    (targetPosition) => {
      animateScrollX(targetPosition, scrollPosition, seriesListRef, setScrollPositionDebounced, isAnimating);
    },
    [scrollPosition, setScrollPositionDebounced]
  );

  // Helper to find the closest episode to scroll to
  const scrollToClosestEpisode = useCallback((direction) => {
    const focusableElements = seriesListRef.current.querySelectorAll(".series .focusable");
    const currentIndex = Array.from(focusableElements).indexOf(document.activeElement);

    if (currentIndex !== -1) {
      const elementWidth = focusableElements[currentIndex].offsetWidth;
      const elementMargin = parseFloat(window.getComputedStyle(focusableElements[currentIndex]).marginRight);
      const scrollOffset = elementWidth + elementMargin + 4;  // Adjust scroll step size
      setCurrentElement(currentIndex);

      if (direction === "right" && currentElement < focusableElements.length - 1) {
        handleScrollX(scrollPosition - scrollOffset);
      }

      if (direction === "left" && currentElement > 0) {
        handleScrollX(scrollPosition + scrollOffset);
      }
    }
  }, [scrollPosition, currentElement, handleScrollX]);

  // Listen for keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight" || event.keyCode === 39) {
        scrollToClosestEpisode("right");
      }

      if (event.key === "ArrowLeft" || event.keyCode === 37) {
        scrollToClosestEpisode("left");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [scrollPosition, scrollToClosestEpisode]);

  return (
    <div className="category-row slide-category-row" id={`${cIndex}-category-${data.category_id}`} ref={categoryListRef} hidden={hidden}>
      {slideView && <h2 className="category-title fw-bold">{data.category_name} | {data.category_tamil}</h2>}
      <div className="series-list-wrapper">
        <div
          className={`series-list ${slideView ? 'd-flex' : ''}`}
          ref={seriesListRef}
          style={{ transform: `translateX(${Math.round(scrollPosition)}px)` }}  // Smooth transition
        >
          {data.series.map((series, index) => (
            <div key={index} className={`series ${slideView ? 'series-slide' : ''}`}>
              <div className={`${slideView ? 'd-flex justify-content-center flex-wrap' : 'grid-layout'}`}>
                {series.is_series ? (
                  <EpisodeCard episode={series.episodes[0]} series={series} />
                ) : (
                  series.episodes.map((episode) => (
                    <EpisodeCard key={episode.episode_id} episode={episode} series={series} />
                  ))
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryRow;
