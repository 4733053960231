import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectApp, setSelectedFilterCategory } from "./appSlice";

const FilterBadges = ({data}) => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectApp).selectedFilterCategory;

  useEffect(() => {
    dispatch(setSelectedFilterCategory(data[0].category_tamil));
  }, [dispatch, data]);

  const handleBadgeClick = (category) => {
    dispatch(setSelectedFilterCategory(category));
  };

  return (
    <div className="category-badges d-flex justify-content-center">
      {data.map((category, index) => (
        <div
          key={index}
          onClick={() => handleBadgeClick(category.category_tamil)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleBadgeClick(category.category_tamil);
            }
          }}
          className={`focusable badge m-2 ${
            selectedCategory === category.category_tamil
              ? "bg-light text-dark"
              : "bg-dark"
          }`}
          tabIndex={0} // Ensure the badges are focusable
        >
          {category.category_tamil}
        </div>
      ))}
    </div>
  );
};

export default FilterBadges;
