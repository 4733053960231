import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataAsync, selectApp, setData } from "../appSlice";
import Loading from "../Loading";
import SpatialNavigation from "spatial-navigation-js";
import "./GridPage.css";
import useSpatialNavigation from "./SpatialNavigationWrapperMovies";
import { animateScrollVY } from "../ScrollUtils";
import { useHistory } from "react-router-dom";
import GridVideos from "../GridVideos/GridVideos";

function GridPage({ category }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, status, data } = useSelector(selectApp);
  const videosRef = useRef(null);

  const uniqueSpatialId = `middlebox-${category}`; // Create a unique ID based on the category

  const handleScrollY = useCallback(
    (targetElement) => {
      animateScrollVY(targetElement, videosRef);
    },
    [videosRef]
  );

  useSpatialNavigation(handleScrollY, uniqueSpatialId); // Pass the unique ID to the hook

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const result = await dispatch(fetchDataAsync(`/gridpage.php?page=${category}`));
        dispatch(setData(result));
      } catch (error) {
        // Handle error
      }
    };

    fetchDataFromApi();

    // Disable unused navigation groups and enable the current one
    SpatialNavigation.disable("middlebox");
    SpatialNavigation.disable("watch-sidebar");
    SpatialNavigation.disable("playbackControls");

    SpatialNavigation.enable(uniqueSpatialId); // Enable the unique group for this category

    return () => {
      SpatialNavigation.remove(uniqueSpatialId); // Cleanup on unmount
    };
  }, [dispatch, category, uniqueSpatialId]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action === "POP") {
        history.replace("/");
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <div className="main-container">
      {isLoading && <Loading />}
      {status === "succeeded" && (
        <div>
          <GridVideos
            categoryListRef={videosRef}
            categories={data}
            spatialElementId={uniqueSpatialId} // Pass the unique ID to the GridVideos component
            slideView={false}
          />
        </div>
      )}
      {status === "failed" && <p>Error loading data</p>}
    </div>
  );
}

export default GridPage;
