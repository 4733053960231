import React, { memo, useEffect } from "react";
import CategoryRow from "../CategoryRow/CategoryRow";
import SpatialNavigation from "spatial-navigation-js";
import "./ListCategories.css";

const ListCategories = memo(({ categoryListRef, categories, spatialElementId, slideView }) => {
  useEffect(() => {
    // Add new navigation configurations
    categories.forEach((category, index) => {
      const id = `category-${category.category_name.toLowerCase()}-${category.category_id}-series-${category.series[0].series_id}-${index}`;
      
      SpatialNavigation.remove(id); // Ensure we remove existing configuration for id before adding it again

      SpatialNavigation.add({
        id: id,
        selector: `#${id} .focusable`,
        enterTo: "last-focused",
        straightOnly: true, // Only navigate in a straight line
        restrict: "self-only", // Restrict navigation within the row
      });
    });

    // Make the elements focusable within their sections
    SpatialNavigation.makeFocusable();

    // Cleanup function to remove sections when the component unmounts or `categories` change
    return () => {
      categories.forEach((category, index) => {
        const id = `category-${category.category_name.toLowerCase()}-${category.category_id}-series-${category.series[0].series_id}-${index}`;
        SpatialNavigation.remove(id);
      });
    };
  }, [categories]);

  return (
    <div id={spatialElementId} ref={categoryListRef} className="categoryList">
      {categories.map((category, index) => (
        <CategoryRow
          key={index}
          data={category}
          categoryListRef={categoryListRef}
          cIndex={index}
          slideView={slideView}
          hidden={false}
        />
      ))}
    </div>
  );
});

export default ListCategories;
